<template>
  <footer>
    <p>&copy; 2024 Estonian Wildlife center</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
footer {
  text-align: center;
  padding: 1rem;
  width: 100%;
  margin-top: 1vh;
}
</style>
